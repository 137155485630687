/*
 * @Date: 2021-12-27 21:14:05
 * @LastEditTime: 2022-01-09 13:09:53
 */
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import reportWebVitals from "./reportWebVitals"
import App from "./App"
// import App from "./App copy"
import { HashRouter, BrowserRouter } from "react-router-dom"
import { DataProvider } from "./context/data-context"

ReactDOM.render(
  <HashRouter>
    <DataProvider>
      <App />
    </DataProvider>
  </HashRouter>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
