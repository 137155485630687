/*
 * @Date: 2021-12-27 21:14:05
 * @LastEditTime: 2022-01-25 15:36:51
 */
import React, { Suspense, useContext } from "react"
import { Routes, Route } from "react-router-dom"
// import FirstPage from "./pages/first"
// import SecondPage from "./pages/second"
// import ThirdPage from "./pages/third"
// import FourthPage from "./pages/fourth"
// import FifthPage from "./pages/fifth"
// import SixthPage from "./pages/sixth"
// import SeventhPage from "./pages/seventh"
// import EighthPage from "./pages/eighth"
// import NinthPage from "./pages/ninth"
// import TenthPage from "./pages/tenth"
import MusicIcon from "./resource/音乐角标.png"
// import Music from "./resource/bg-music.wav"
import styled from "styled-components"
// import Bg2 from "./resource/bg/2-bg.png"
import { DataContext } from "./context/data-context"
import "./index.css"

const FirstPage = React.lazy(() => import("./pages/first"))
const SecondPage = React.lazy(() => import("./pages/second"))
const ThirdPage = React.lazy(() => import("./pages/third"))
const FourthPage = React.lazy(() => import("./pages/fourth"))
const FifthPage = React.lazy(() => import("./pages/fifth"))
const SixthPage = React.lazy(() => import("./pages/sixth"))
const SeventhPage = React.lazy(() => import("./pages/seventh"))
const EighthPage = React.lazy(() => import("./pages/eighth"))
const NinthPage = React.lazy(() => import("./pages/ninth"))
const TenthPage = React.lazy(() => import("./pages/tenth"))

const staticUrl =
  "https://gdh5.gdong.net/media1/res/qixiGroupup/2021end/static/img/"
const staticUrl2 =
  "https://gdh5.gdong.net/media1/res/qixiGroupup/2021end/static/sound/"

// 女
const Bg2 = staticUrl + "bg.png"
// 男
const Bg = staticUrl + "9-bg.png"
const Music = staticUrl2 + "bg-music.mp3"

const RotateAnimationImg = styled.img`
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`
const App: React.FunctionComponent = () => {
  const [play, setPlay] = React.useState(false)
  let { loading, data } = useContext(DataContext)
  const [curAudio, setCurAudio] = React.useState<any>(null)
  React.useEffect(() => {
    const audio = document.getElementById(`audio`)
    setCurAudio(audio)
  }, [])
  if (loading) {
    return <div>读取中...</div>
  } else {
    return (
      <div
        style={{
          position: "relative",
          backgroundImage: data.UserSex === "1" ? `url(${Bg})` : `url(${Bg2})`,
          backgroundPosition: "fixed",
          width: "100%",
          height: "100vh",
        }}
      >
        <div style={{ display: "none" }}>
          <audio
            // autoPlay={true}
            controls
            preload='auto'
            loop
            // muted={muted}
            id={`audio`}
          >
            <source src={Music} type='audio/mp3' />
            Your browser does not support the audio element.
          </audio>
        </div>
        {!play ? (
          <img
            src={MusicIcon}
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "transparent",
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1000,
            }}
            alt=''
            onClick={() => {
              curAudio.play()
              setPlay(true)
            }}
          />
        ) : (
          <RotateAnimationImg
            src={MusicIcon}
            style={{
              width: "50px",
              height: "50px",
              backgroundColor: "transparent",
              position: "absolute",
              top: "20px",
              right: "20px",
              zIndex: 1000,
            }}
            alt=''
            onClick={() => {
              curAudio.pause()
              setPlay(false)
            }}
          />
        )}
        <Suspense fallback={<div>读取中。。。</div>}>
          <Routes>
            <Route
              path='/first'
              element={
                <FirstPage
                  play={() => {
                    curAudio.play()
                    setPlay(!play)
                  }}
                />
              }
            />
            <Route path='/second' element={<SecondPage />} />
            <Route path='/third' element={<ThirdPage />} />
            <Route path='/fourth' element={<FourthPage />} />
            <Route path='/fifth' element={<FifthPage />} />
            <Route path='/sixth' element={<SixthPage />} />
            <Route path='/seventh' element={<SeventhPage />} />
            <Route path='/eighth' element={<EighthPage />} />
            <Route path='/ninth' element={<NinthPage />} />
            <Route path='/tenth' element={<TenthPage />} />
            <Route
              path='/'
              element={
                <FirstPage
                  play={() => {
                    curAudio.play()
                    setPlay(!play)
                  }}
                />
              }
            />
          </Routes>
        </Suspense>
      </div>
    )
  }
}

export default App
