/*
 * @Date: 2022-01-06 08:33:05
 * @LastEditTime: 2022-01-21 16:54:59
 */
import React, { ReactNode } from "react"
import { http } from "../utils/http"
import MockData from "../service/mock.json"
import { DataSchema, ResSchema } from "../service/schema"

const DataContext = React.createContext<{
  data: any
  loading: boolean
}>({ data: MockData, loading: false })
DataContext.displayName = "DataContext"
// const DataContext = (init: any) => React.useContext(Context)

const DataProvider = ({ children }: { children: ReactNode }) => {
  const [data, setData] = React.useState<DataSchema>(MockData.data)
  const [loading, setLoading] = React.useState(false)
  const getData = async () => {
    let resData = null
    console.log(window.location.hash)
    let userid = "234234"
    let token = ""
    try {
      let userInfo = window.location.hash.split("&")[0]
      let tokenInfo = window.location.hash.split("&")[1]
      // userid = window.location.hash.split("=")[1]
      userid = userInfo.split("=")[1]
      token = tokenInfo.split("=")[1]
    } catch (e) {
      console.log(e)
    }
    setLoading(true)
    try {
      const data: ResSchema = await http("/Handler/Report.ashx", {
        data: { userid: userid },
      })
      resData = data.data
      if (data.errcode === 0) {
        setData(resData)
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      setLoading(false)
      return null
    }
    return resData
  }
  React.useEffect(() => {
    getData()
  }, [])

  return (
    <DataContext.Provider value={{ data, loading }}>
      {children}
    </DataContext.Provider>
  )
}
export { DataContext, DataProvider }
