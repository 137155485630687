/*
 * @Author: your name
 * @Date: 2021-05-02 15:14:41
 * @LastEditTime: 2022-01-06 10:08:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /maintenance_app/src/utils/http.ts
 */

import qs from "qs"

interface Config extends RequestInit {
  token?: string
  data?: object
}

export const http = async (
  endpoint: string,
  { data, token, headers, ...customConfig }: Config = {}
) => {
  const config = {
    method: "GET",
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      "Content-Type": data ? "application/json" : "",
    },
    ...customConfig,
  }
  if (config.method.toUpperCase() === "GET") {
    endpoint += `?${qs.stringify(data)}`
  } else {
    config.body = JSON.stringify(data || {})
  }
  return window.fetch(`${endpoint}`, config).then(async (response) => {
    if (response.status === 401) {
      window.location.reload()
      return Promise.reject({ message: "请重新登录" })
    }
    const data = await response.json()
    if (response.ok) {
      return data
    } else {
      return Promise.reject(data)
    }
  })
}
